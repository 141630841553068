<template>
  <div>
    <div>
      <div class="row">
        <div class="col-8 row self-stretch">
          <div class="self-stretch col row items-center">
            <div
              class="self-stretch col row items-center cursor-pointer q-pr-xs"
              @click="$emit('viewPondDetail', Pond)"
            >
              <span class="text-subtitle1">
                <u>{{ Pond.name }}</u>
              </span>
            </div>
            <div class="col-8 self-stretch row items-center text-caption">
              <div
                v-if="LastFlavor"
                class="col-6 self-stretch row items-center cursor-pointer q-px-xs"
                @click="$emit('viewPondDetail', Pond)"
              >
                <u>
                  {{ LastFlavor.flavor_name }}
                  - {{ LastFlavor.severity }}
                </u>
              </div>

              <div v-else class="col-6 text-grey-5 q-px-xs">
                <div>- - -</div>
              </div>

              <div
                v-if="CurrentFlavor"
                class="col-6 self-stretch row items-center cursor-pointer q-px-xs"
                @click="editFlavorEntry(CurrentFlavor)"
              >
                <u>
                  {{ CurrentFlavor.flavor_name }}
                  - {{ CurrentFlavor.severity }}
                </u>
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col row items-center q-py-sm">
          <q-btn
            unelevated
            color="grey-4"
            textColor="black"
            padding="10px 0px"
            class="full-width"
            size="md"
            @click="flavorPond()"
            @click.stop
          >
            <div class="row items-center justify-around full-width">
              <div style="width: 30px"></div>
              <div>Flavor</div>
              <div v-if="CurrentFlavor" style="width: 30px; height: 24px">
                <q-icon name="task_alt" color="primary" />
              </div>
              <div v-else style="width: 30px; height: 24px"></div>
            </div>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Flavor Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogFlavorPond">
      <FlavorPond
        :Action="action"
        :Flavor="flavor"
        :Pond="Pond"
        @close="dialogFlavorPond = false"
      />
    </q-dialog>
  </div>
</template>

<script>
import FlavorPond from '@/components/ponds/FlavorPond.vue';

import { decoder, numberWithCommas, isNumValid, cloneObj } from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

function defaultFlavor() {
  return {
    id: null,
    user_id: store.state.user.id,
    proc_id: null,
    flavor: null,
    severity: 0,
    date: +new Date() / 1000,
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'FlavorPondRow',
  components: {
    FlavorPond
  },
  props: {
    Pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      dialogFlavorPond: false,
      decoder,
      isDateValid,
      numberWithCommas,
      parseTimeStamp,
      poundsCopy: null,
      qtyErr: false,
      flavor: defaultFlavor()
    };
  },
  // mounted() {},
  methods: {
    editFlavorEntry(flavor) {
      this.action = 'update';
      this.flavor = {
        ...defaultFlavor(),
        ...flavor
      };
      this.dialogFlavorPond = true;
    },
    flavorPond() {
      this.action = 'create';
      this.flavor = defaultFlavor();

      this.dialogFlavorPond = true;
    }
  },
  computed: {
    CurrentFlavor() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

      // if no initial flavor, return null
      if (this.Pond.flavorHistory.length === 0) {
        return null;
      }

      // find the most recent flavor
      const currFlavor = this.Pond.flavorHistory.reduce((a, b) => {
        if (a.date > b.date) {
          return a;
        }
        return b;
      });

      const currFlavorDate = date.formatDate(
        currFlavor.date * 1000,
        'YYYY-MM-DD'
      );

      return currDate === currFlavorDate ? currFlavor : null;
    },
    LastFlavor() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
      let lastFlavor = null;

      let lastDate = null;
      let continued = false;
      for (let i = 0; i < this.Pond.flavorHistory.length; i++) {
        const flavor = this.Pond.flavorHistory[i];

        const flavorDate = date.formatDate(
          new Date(flavor.date * 1000),
          'YYYY-MM-DD'
        );

        if (flavorDate === currDate && !continued) {
          continued = true;
          continue;
        } else {
          lastFlavor = cloneObj(flavor);

          break;
        }
      }

      return lastFlavor;
    },
    FlavorAmount() {
      let amount = parseFloat(this.flavor.flavor);

      const decimalCount = this.flavor.flavor.split('.').length - 1;

      if (this.treatment.flavor.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.treatment.flavor === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    }
  }
};
</script>

<style scoped>
.full-height {
  height: 100%;
  min-height: 100%;
}
</style>
